export const CorrectIcon = ({ style }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      width="24"
      height="24"
      role="presentation"
      focusable="false"
      {...style}
    >
      <path
        d="M12 4c-5.159 0-8 2.841-8 8s2.841 8 8 8 8-2.841 8-8-2.841-8-8-8Zm-1 11.56-3.03-3.03 1.06-1.06L11 13.44l3.97-3.97 1.06 1.06L11 15.56Z"
        fill="currentColor"
        vector-effect="non-scaling-stroke"
      ></path>
    </svg>
  );
};
