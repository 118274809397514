export const Minus = ({ style }) => {
  return (
    <svg viewBox="0 0 512 512" style={{ ...style }}>
      <g>
        <g>
          <g>
            <path d="M256,48C141.1,48,48,141.1,48,256s93.1,208,208,208c114.9,0,208-93.1,208-208S370.9,48,256,48z M256,446.7     c-105.1,0-190.7-85.5-190.7-190.7c0-105.1,85.5-190.7,190.7-190.7c105.1,0,190.7,85.5,190.7,190.7     C446.7,361.1,361.1,446.7,256,446.7z" />
          </g>
        </g>
        <rect height="17" width="256" x="128" y="248" />
      </g>
    </svg>
  );
};
