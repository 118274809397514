export default function PictureIcon({ style }) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      {...style}
      role="presentation"
      focusable="false"
    >
      <path
        d="M10 11.5a1.392 1.392 0 0 0 1.5-1.5A1.392 1.392 0 0 0 10 8.5 1.393 1.393 0 0 0 8.5 10a1.393 1.393 0 0 0 1.5 1.5Z"
        fill="currentColor"
        vector-effect="non-scaling-stroke"
      ></path>
      <path
        d="m19.01 12.915-.01-.008V5H5v14h14v-6.075l.01-.01ZM17.5 6.5v5.015l-.091-.085a3.254 3.254 0 0 0-4.596 0l-2.171 2.17a.77.77 0 0 1-1.06 0l-.38-.38a2.25 2.25 0 0 0-2.7-.354V6.5H17.5Zm-11 11v-2.636l.58-.58a.75.75 0 0 1 1.06 0l.38.38a2.25 2.25 0 0 0 3.181 0l2.172-2.172a1.76 1.76 0 0 1 2.495.02L17.5 13.56v3.94h-11Z"
        fill="currentColor"
        vector-effect="non-scaling-stroke"
      ></path>
    </svg>
  );
}
