import { SvgIcon } from "@mui/material";

export default function CodeIcon({ style }) {
  return (
    <SvgIcon sx={style}>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1792"
        height="1792"
        viewBox="0 0 1792 1792"
      >
        <path d="m553 1399-50 50q-10 10-23 10t-23-10L-9 983q-10-10-10-23t10-23l466-466q10-10 23-10t23 10l50 50q10 10 10 23t-10 23L160 960l393 393q10 10 10 23t-10 23zm591-1067L771 1623q-4 13-15.5 19.5T732 1645l-62-17q-13-4-19.5-15.5T648 1588l373-1291q4-13 15.5-19.5t23.5-2.5l62 17q13 4 19.5 15.5t2.5 24.5zm657 651-466 466q-10 10-23 10t-23-10l-50-50q-10-10-10-23t10-23l393-393-393-393q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l466 466q10 10 10 23t-10 23z"></path>
      </svg> */}
      <svg
        viewBox="-7 -7 40 40"
        fill="none"
        role="img"
        focusable="false"
        width="40"
        height="40"
        class="dig-ContentIcon brws-file-name-cell-icon dig-ContentIcon--small dig-ContentIcon--overBase"
        data-testid="fallback-file-thumbnail"
      >
        <path
          d="M21.7568 2.5H4.24324C3.19499 2.5 2.38362 2.70706 1.83172 3.11547C1.27982 3.52383 1 4.12424 1 4.9V23.1C1 23.8757 1.27982 24.4761 1.83172 24.8845C2.38355 25.2929 3.19492 25.5 4.24324 25.5H21.7568C22.805 25.5 23.6164 25.2929 24.1683 24.8845C24.7202 24.4762 25 23.8758 25 23.1V4.9C25 4.12429 24.7202 3.52388 24.1683 3.11547C23.6164 2.70706 22.8051 2.5 21.7568 2.5Z"
          fill="var(--dig-color__fileicon__shadow, #bfbfbf)"
        ></path>
        <path
          d="M21.7568 2H4.24324C3.19499 2 2.38362 2.20706 1.83172 2.61547C1.27982 3.02383 1 3.62424 1 4.4V21.6C1 22.3757 1.27982 22.9761 1.83172 23.3845C2.38355 23.7929 3.19492 24 4.24324 24H21.7568C22.805 24 23.6164 23.7929 24.1683 23.3845C24.7202 22.9762 25 22.3758 25 21.6V4.4C25 3.62429 24.7202 3.02388 24.1683 2.61547C23.6164 2.20706 22.8051 2 21.7568 2Z"
          fill="var(--dig-color__fileicon__container, #f7f5f2)"
        ></path>
        <path
          d="m9.42 9.03-3.526 3.762 3.525 3.76.912-.854-2.724-2.906 2.724-2.906-.912-.855Zm6.953 0-.912.856 2.724 2.906-2.724 2.905.912.855 3.525-3.76-3.525-3.761Zm-2.94-1.795-2.5 10.833 1.217.28 2.501-10.832-1.218-.281Z"
          fill="var(--dig-color__fileicon__icon, #777471)"
        ></path>
        <path
          d="m10.932 18.068 2.501-10.833 1.218.28-.108.471-1.11-.256-2.392 10.363-.109-.025ZM9.42 9.03l-3.526 3.762.232.247 3.293-3.513.665.623.247-.263-.912-.855Zm.664 6.9.247-.232-2.492-2.659-.232.248 2.477 2.642Zm6.289-6.9-.912.856.247.263.665-.623 3.293 3.513.232-.247-3.525-3.761Zm-.665 6.9 2.477-2.643-.232-.248-2.492 2.659.247.231Z"
          fill="var(--dig-color__fileicon__icon-shadow, #6c6966)"
        ></path>
      </svg>
    </SvgIcon>
  );
}
