import "./Eye.css";

export const EyeCrossIcon = ({ style, onClick }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      className="eye"
      width="24"
      height="24"
      role="presentation"
      focusable="false"
      {...style}
      onClick={onClick}
    >
      <path
        d="M20.177 11.678a12.025 12.025 0 0 0-1.988-2.806l-1.073 1.072A11.293 11.293 0 0 1 18.65 12c-.62 1.082-2.879 4.5-6.65 4.5a5.95 5.95 0 0 1-1.291-.15l-1.227 1.227A7.68 7.68 0 0 0 12 18c5.412 0 8.067-5.446 8.177-5.678l.154-.322-.154-.322ZM16.97 5.97l-1.074 1.073A7.782 7.782 0 0 0 12 6c-5.411 0-8.066 5.446-8.177 5.678L3.669 12l.154.322a12.032 12.032 0 0 0 3.024 3.77l-.877.878 1 1 11-11-1-1Zm-3.78 3.78A2.845 2.845 0 0 0 12 9.5 2.321 2.321 0 0 0 9.5 12c-.005.41.08.817.248 1.192l-1.823 1.822A10.744 10.744 0 0 1 5.35 12c.62-1.081 2.879-4.5 6.65-4.5a6.176 6.176 0 0 1 2.773.667L13.19 9.75Z"
        fill="currentColor"
        vector-effect="non-scaling-stroke"
      ></path>
    </svg>
  );
};
